
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "A valid email is required",
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ProviderCreate extends Vue {
  public el = 1;
  public editedItem = {
    independent: false,
    assistant: {
      first_name: null,
      last_name: null,
    },
  };
  private readonly loading = false;
}
